import { render, staticRenderFns } from "./ModalEditorDeNoticias.vue?vue&type=template&id=eaa8a396&scoped=true"
import script from "./ModalEditorDeNoticias.vue?vue&type=script&lang=ts"
export * from "./ModalEditorDeNoticias.vue?vue&type=script&lang=ts"
import style0 from "./ModalEditorDeNoticias.vue?vue&type=style&index=0&id=eaa8a396&prod&lang=css"
import style1 from "./ModalEditorDeNoticias.vue?vue&type=style&index=1&id=eaa8a396&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eaa8a396",
  null
  
)

export default component.exports