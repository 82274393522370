export const Constantes = {
  email: {
    caritas: "santodomingodecartagena@gmail.com",
    iglesia: "santodomingodecartagena@gmail.com",
  },
  tlf:{
    caritas:"",
    iglesia:"+34968504759"
  },
  direccion:" C/Mayor, 28 - 30201, Cartagena, España",
  logo_nombre_sitio:"logo Parroquia Castrense de Santo Domingo Cartagena",
  nombre_sitio:"Parroquia Castrense de Santo Domingo Cartagena",
  sitio_web:"https://santodomingocartagena.com",
  nombre_ciudad:"Cartagena",
};
